import React from 'react';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import RichTextPreview from 'rev.sdk.js/Components/RichTextPreview';
import qs from 'query-string';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';

function ArticleDetail(props) {
  const {title, header, query} = props.pageContext;
  const [record, setRecord] = React.useState(null);

  const params = qs.parse(props.location.search);
  const {id} = params;

  const fetchData = React.useCallback(async () => {
    try {
      AppActions.setLoading(true);
      if (id) {
        setRecord(
          await JStorage.fetchOneDocument('Article_Default', {_id: {$oid: id}}),
        );
      } else {
        setRecord(
          await JStorage.fetchOneDocument('Article_Default', {title, ...query}),
        );
      }

      AppActions.setLoading(false);
    } catch (err) {
      console.warn(err);
      AppActions.setLoading(false);
    }
  }, [id, title, query]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Wrapper>
      <div className="container">
        <h1 className="title">{header || record?.title}</h1>
        {record && (
          <div className="content">
            <RichTextPreview key={record.id} content={record.content} />
          </div>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0px;
  max-width: var(--contentMaxWidth);
  width: 100%;
  min-height: 100vh;
  padding: 40px 20px;

  & > .container {
    max-width: 910px;
    width: 100%;
    margin: 0 auto;

    & > .title {
      margin-bottom: 15px;
    }
  }
`;

export default withPageEntry(ArticleDetail);
